import React, { useState, useEffect } from "react";
import "./App.css";
import Confetti from "react-confetti";

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}

function App() {
  const [number, setNumber] = useState("");
  const [isCorrectNumber, setIsCorrectNumber] = useState(false);
  const [correctNumber, setCorrectNumber] = useState(
    Math.round(Math.random() * 10).toString()
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLoginClick = () => {
    setIsModalOpen(true);
  };

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    if (number === correctNumber) {
      setIsCorrectNumber(true);
      setNumber("");
    } else {
      alert(
        `That was the wrong number, the number was ${correctNumber}, the number has changed, Try Again!`
      );
      setNumber("");
      setCorrectNumber(Math.round(Math.random() * 10).toString());
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img
          src="/encryptedentropy.webp"
          alt="fox logo"
          className="title-image"
        />
        {isCorrectNumber ? (
          <div>
            <Confetti />
            <div className="celebration">
              <h1>Congratulations!</h1>
              <p>You guessed the correct number!</p>
            </div>
          </div>
        ) : null}
        {!(isModalOpen || isCorrectNumber) && (
          <button onClick={handleLoginClick}>Login</button>
        )}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <form onSubmit={handleSubmit} className="login-form">
            <label className="label">Enter the number:</label>
            <input
              type="text"
              value={number}
              onChange={handleNumberChange}
              className="input-field"
            />
            <button type="submit">Submit</button>
          </form>
        </Modal>
      </header>
    </div>
  );
}

export default App;
